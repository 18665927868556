import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(({ palette, breakpoints }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: palette.supporting.green.light,
    borderRadius: rem(8),
    [breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },

  blockText: {
    display: 'flex',
    flexDirection: 'column',
    gap: rem(40),
    padding: rem(24),
    [breakpoints.up('md')]: {
      width: '61%',
      padding: rem(52),
    },

    '& div:first-child': {
      display: 'flex',
      flexDirection: 'column',
      gap: rem(16),
    },
  },
  blockImg: {
    position: 'relative',
    display: 'flex',
  },

  shape: {
    width: '100%',
    height: 'unset',
    alignSelf: 'flex-end',
    marginBottom: rem(16),
    [breakpoints.up('md')]: {
      marginBottom: rem(24),
    },
  },
  picture: {
    position: 'absolute',
    right: 0,
    left: 0,
    margin: 'auto',
    bottom: 0,
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    objectPosition: 'bottom',
  },
  title: {
    fontSize: rem(20),
    fontWeight: 700,
    [breakpoints.up('md')]: {
      fontSize: rem(32),
    },
  },
}));
