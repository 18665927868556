import { ComponentProps, FC, HTMLAttributeAnchorTarget } from 'react';
import { Typography } from '@mui/material';

import Icon from '@/designSystem/Icon/Icon';

import useStyles from './styles';

type BaseButtonSize = 'small' | 'medium' | 'large';
type BaseButtonVariant = 'primary' | 'secondary' | 'tertiary' | 'ghost';
type BaseButtonColor = 'brand' | 'danger';
type BaseButtonCornerSide = 'left' | 'right' | 'all' | 'none';

export type BaseButtonProps = {
  color?: BaseButtonColor;
  cornerSide?: BaseButtonCornerSide;
  href?: string;
  icon?: ComponentProps<typeof Icon>['icon'];
  iconColor?: ComponentProps<typeof Icon>['color'];
  isDisabled?: boolean;
  isFullWidth?: boolean;
  label?: string;
  size?: BaseButtonSize;
  target?: HTMLAttributeAnchorTarget;
  variant?: BaseButtonVariant;
};

const getTypographyVariant = (size: BaseButtonProps['size']) => {
  switch (size) {
    case 'small':
      return 'bodySmBold';
    case 'medium':
      return 'bodyMdBold';
    case 'large':
    default:
      return 'bodyLgBold';
  }
};

const getIconSize = (size: BaseButtonProps['size']) => {
  switch (size) {
    case 'small':
      return 'medium';
    case 'medium':
    case 'large':
    default:
      return 'large';
  }
};

const BaseButton: FC<BaseButtonProps> = ({
  color = 'brand',
  cornerSide = 'none',
  href,
  icon,
  iconColor,
  isDisabled = false,
  isFullWidth = false,
  label,
  size = 'medium',
  target = '_self',
  variant = 'primary',
}) => {
  const { classes } = useStyles({
    color,
    cornerSide,
    isDisabled,
    isFullWidth,
    size,
    variant,
    withIcon: !!icon,
    withText: !!label,
  });

  return (
    <a
      className={classes.container}
      // eslint-disable-next-line react/button-has-type
      tabIndex={0}
      href={href}
      target={target}
    >
      {!!icon && (
        <Icon icon={icon} size={getIconSize(size)} color={iconColor} />
      )}
      {!!label && (
        <Typography
          className={classes.label}
          component="span"
          variant={getTypographyVariant(size)}
        >
          {label}
        </Typography>
      )}
    </a>
  );
};

export default BaseButton;
