import { FC, ReactElement } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Typography } from '@mui/material';
import Image from 'next/image';
import CONSTANCE_IMG_URL from 'src/components/LandingMozza/common/RaiseIncome/constants';
import messages from 'src/components/LandingMozza/common/RaiseIncome/messages';
import useStyles from 'src/components/LandingMozza/common/RaiseIncome/styles';

import ShapeSvg from '@/components/LandingMozza/common/RaiseIncome/ShapeSvg';
import LinkAsButton from '@/designSystem/LinkAsButton/LinkAsButton';
import { getImageFromCloudImage } from '@/utils/cloudFrontLoader';
import { useIsMobile } from '@/utils/useResponsive';

type Props = {
  title?: string | ReactElement;
  description?: string | ReactElement;
  buttonLabel?: string;
  privateCoursePageURL: string;
};

const RaiseIncome: FC<Props> = ({
  title,
  description,
  buttonLabel,
  privateCoursePageURL,
}) => {
  const { classes } = useStyles();
  const { formatMessage } = useIntl();
  const isMobile = useIsMobile();

  return (
    <div className={classes.container}>
      <div className={classes.blockText}>
        <div>
          <Typography component="h2" className={classes.title}>
            {title ?? <FormattedMessage {...messages.title} />}
          </Typography>
          <Typography component="p">
            {description ?? <FormattedMessage {...messages.description} />}
          </Typography>
        </div>
        <LinkAsButton
          size="large"
          href={privateCoursePageURL}
          isFullWidth={isMobile}
          label={buttonLabel ?? formatMessage({ ...messages.buttonLabel })}
        />
      </div>
      <div className={classes.blockImg}>
        <ShapeSvg className={classes.shape} />
        <Image
          src={CONSTANCE_IMG_URL}
          width={330}
          height={330}
          className={classes.picture}
          loader={getImageFromCloudImage}
          quality={100}
          alt={formatMessage({ ...messages.imgAlt })}
        />
      </div>
    </div>
  );
};

export default RaiseIncome;
