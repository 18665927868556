import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'landingMozza.raiseIncome.title',
    defaultMessage:
      'Comme Constance, augmente tes revenus en donnant des cours particuliers',
  },
  description: {
    id: 'landingMozza.raiseIncome.description',
    defaultMessage:
      'Les Sherpas recherchent toujours des professeurs particuliers pour accompagner collégiens, lycéens et étudiants de prépa et du supérieur, en visio ou à domicile.',
  },
  buttonLabel: {
    id: 'landingMozza.raiseIncome.button.label',
    defaultMessage: 'Devenir professeur',
  },
  imgAlt: {
    id: 'landingMozza.raiseIncome.img.alt',
    defaultMessage:
      'Constance donne des cours particuliers sur Sherpas.com en tant que prof particulier',
  },
});
